import { Override } from "../types";
import { AbstractLinearTeam as AbstractLinearTeamDto, LinearIntegration as LinearIntegrationDto } from "./client";
import { Domain } from "./types";

const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;

export type LinearTeam = Override<
  AbstractLinearTeamDto,
  {
    readonly id: string;
    readonly key: string;
    readonly name: string;
  }
>;

export type LinearIntegration = Override<
  LinearIntegrationDto,
  {
    readonly id: string;
    readonly availableTeams?: LinearTeam[];
  }
>;

export class IntegrationsDomain extends Domain {
  /* Zoom */

  getZoomIntegration = this.manageErrors(this.api.integrations.getZoomIntegration);

  getZoomEnabled = this.manageErrors(() => {
    return this.api.integrations
      .getZoomIntegration()
      .then((zoom) => !!zoom && !!zoom.id)
      .catch(() => false);
  });

  /* Linear */

  getLinearIntegrations = this.manageErrors(this.api.linear.integrations1);

  patchLinearIntegration = (id: string, data: Partial<LinearIntegration>) => {
    return this.api.linear.patchIntegration1(id, data);
  };

  deleteLinearIntegration = (id: string) => {
    return this.api.linear.deleteIntegration1(id);
  };

  getLinearEnabled = this.manageErrors(() => {
    return this.api.linear
      .integrations1()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRedirect(redirectUri: string, state: any, newTab?: boolean) {
    const url = new URL(`${OAUTH_BASE_URI}${redirectUri}`, window.location.href);
    url.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));

    if (newTab) {
      window.open(url.toString());
    } else {
      window.location.href = url.toString();
    }
  }
}
